<template lang="pug">
b-select.area-select(
  :value="area"
  autocomplete="region"
  :disabled="disabled"
  @input="inputHandler"
  :expanded="expanded"
  :name="name"
)
  option(
    value=""
    disabled
    selected
  ) {{ actionLocaleText('choose_one') }}
  option(
    v-for="(area, index) in areas"
    :key="index"
    :value="area"
  ) {{ area }} ({{ getZipCode(area) }})
</template>

<script>
import { defineComponent, computed, watch, onMounted } from 'vue'
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'

export default defineComponent({
  props: {
    city: {
      type: String,
      required: false
    },

    area: {
      type: String,
      required: false
    },

    zip: {
      type: [String, Number],
      requird: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },

    expanded: {
      type: Boolean,
      required: false
    },

    name: {
      type: String,
      required: false
    }
  },

  setup(props, { emit }) {
    const cities = computed(() => {
      return Object.keys(TaiwanZipCodes)
    })

    const areaZips = computed(() => {
      if (!props.city) return {}

      return TaiwanZipCodes[props.city]
    })

    const getZipCode = (area) => {
      return areaZips.value[area]
    }

    const areas = computed(() => {
      return Object.keys(areaZips.value)
    })

    const currentCity = computed(() => {
      return props.city
    })

    watch(currentCity, () => {
      if (!areas.value.includes(props.area)) emit('update:area', '')
    })

    const inputHandler = (value) => {
      emit('update:area', value)
      emit('update:zip', getZipCode(value))
      emit('input')
    }

    onMounted(() => {
      if (props.area && !props.zip) emit('update:zip', getZipCode(props.area))
    })

    return {
      cities,
      getZipCode,
      areas,
      inputHandler
    }
  }
})
</script>
