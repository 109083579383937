<template lang="pug">
b-select(
  :value="city"
  :disabled="disabled"
  autocomplete="locality"
  @input="inputHandler"
  :expanded="expanded"
  :name="name"
)
  option(
    value=""
    disabled
    selected
  ) {{ actionLocaleText('choose_one') }}
  option(
    v-for="(city, index) in cities"
    :key="index"
    :value="city"
  ) {{ city }}
</template>

<script>
import { defineComponent, computed } from 'vue'
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'

export default defineComponent({
  props: {
    city: {
      type: String,
      required: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },

    expanded: {
      type: Boolean,
      required: false
    },

    name: {
      type: String,
      required: false
    }
  },

  setup(props, { emit }) {
    const cities = computed(() => {
      return Object.keys(TaiwanZipCodes)
    })

    const inputHandler = (value) => {
      emit('update:city', value)
      emit('input')
    }

    return {
      cities,
      inputHandler
    }
  }
})
</script>
